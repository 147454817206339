<template>
	<div id="main">
		<div id="header">
			<h1>Order Details</h1>
			<p v-text="'For order: ' + orderId"></p>
		</div>

		<main v-if="error" class="row">
			<div class="col-xs-12" style="text-align: center">
				<p v-text="error"></p>
			</div>
		</main>

		<main class="row" v-else-if="Object.keys(data).length > 0">
			<div
				v-for="(field, index) in data"
				:key="index"
				class="part col-md-6 col-xs-12"
			>
				<div
					v-if="
						['customDeletionId', 'fieldOrder', 'raw'].includes(
							index
						)
					"
				></div>
				<div v-else>
					<h2 v-text="keyToTitle(index)"></h2>
					<div
						v-if="
							![
								'existingLogos',
								'competitorLogos',
								'timestamp',
								'mustHavesAndDoNotTouchDetails',
								'aLogoOrBrandYouAdmire',
								'anythingElseToConsider'
							].includes(index)
						"
					>
						<ul>
							<span v-for="(item, iIndex) in field" :key="iIndex">
								<li v-if="item !== ''">
									<p
										v-html="
											`${
												isNaN(iIndex)
													? '<strong>' +
													  keyToTitle(iIndex) +
													  '</strong>'
													: ''
											}${removeLeadingColon(item)}`
										"
									></p>
								</li>
							</span>
						</ul>
					</div>
					<div v-else-if="index === 'existingLogos'">
						<div
							v-for="(url, existingLogoIndex) in orderImages"
							:key="existingLogoIndex"
						>
							<div
								v-if="
									cleanupUrl(url).length > 6 &&
									cleanupUrl(url).substr(0, 6) === '_logo_'
								"
							>
								<img
									:src="url"
									alt="Logo"
									@click="downloadImg(url)"
								/>
							</div>
						</div>
					</div>
					<div v-else-if="index === 'competitorLogos'">
						<div
							v-for="(url, competitorLogoIndex) in orderImages"
							:key="competitorLogoIndex"
						>
							<div
								v-if="
									cleanupUrl(url).length > 12 &&
									cleanupUrl(url).substr(0, 12) ===
										'_competitor_'
								"
							>
								<img
									:src="url"
									alt="Competitor logo"
									@click="downloadImg(url)"
								/>
							</div>
						</div>
					</div>
					<div v-else-if="index === 'aLogoOrBrandYouAdmire'">
						<div
							v-for="(
								url, aLogoOrBrandYouAdmireIndex
							) in orderImages"
							:key="aLogoOrBrandYouAdmireIndex"
						>
							<div
								v-if="
									cleanupUrl(url).length > 8 &&
									cleanupUrl(url).substr(0, 8) === '_admire_'
								"
							>
								<img
									:src="url"
									alt="Logo or brand you admire"
									@click="downloadImg(url)"
								/>
							</div>
						</div>
					</div>
					<div v-else>
						<ul>
							<li>
								<p
									v-html="
										`<strong>${keyToTitle(index)}</strong>${
											index === 'timestamp'
												? timestampToDateTime(field)
												: field
										}`
									"
								></p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>

		<main v-else class="row">
			<div
				class="col-xs-12"
				style="text-align: center; background-color: var(--accent)"
			>
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
	import { fireGet, fireStorageGetAll, fireAsyncSignIn } from '../db';

	export default {
		name: 'Order',
		props: {
			orderId: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				data: {},
				orderImages: [],
				error: '',
				retries: 0
			};
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					this.loadOrder();
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		},
		methods: {
			loadOrder() {
				fireGet(this.orderId).then(data => {
					if (data) {
						if (Object.keys(data).includes('raw')) {
							this.data = JSON.parse(data.raw);
						} else {
							// Deprecated
							let dataClone = JSON.parse(JSON.stringify(data));
							if (Object.keys(data).includes('youDetails')) {
								const object2 = { you: data.youDetails };
								dataClone = { ...data, ...object2 };
							}
							this.data = this.organizeData(
								this.sortObject(dataClone)
							);
						}
					} else {
						this.data = {};
						this.error = 'This order could not be found.';
					}
				});

				fireStorageGetAll()
					.then(images => {
						images.forEach(image => {
							if (image.fullPath.includes(this.orderId)) {
								image.getDownloadURL().then(url => {
									this.orderImages.push(url);
								});
							}
						});
					})
					.catch(e => {
						console.error(`getAll Storage Error ${e}`);
					});
			},
			removeLeadingColon(item) {
				if (
					item.length > 2 &&
					item.charAt(0) === ':' &&
					item.charAt(1) === ' '
				) {
					return item.substring(2);
				}

				return item;
			},
			keyToTitle(key) {
				let res = '';
				for (let char of key) {
					if (char.toUpperCase() === char) {
						res += ' ';
					}

					if ((res && res[res.length - 1] === ' ') || !res) {
						char = char.toUpperCase();
					}

					res += char;
				}

				res = res === 'Style Freference' ? 'Style Preference' : res;

				return `${res}: `;
			},
			timestampToDateTime(timestamp) {
				const d = new Date(timestamp);

				return `${d.toLocaleDateString(undefined, {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric'
				})}`;
			},
			sortObject(o) {
				// Deprecated

				try {
					return Object.keys(o)
						.sort()
						.reduce((r, k) => ((r[k] = o[k]), r), {});
				} catch {
					return o;
				}
			},
			cleanupUrl(url) {
				return url
					.replace(
						'https://firebasestorage.googleapis.com/v0/b/brandpro-dev.appspot.com/o/orders%2F',
						''
					)
					.replace(this.orderId, '')
					.replace('?alt=media&token=', '');
			},
			downloadImg(url) {
				window.open(url);
			},
			organizeData(raw) {
				// Deprecated

				const fieldsInOrder = [
					'you',
					'brandDetails',
					'companyType',
					'existingLogos',
					'mustHavesAndDoNotTouchDetails',
					'companyOrBrandPersonality',
					'personalityAttributesPairs',
					'personalityAttributesOpposites',
					'colorCombinationPreferences',
					'fontCategoryPreferences',
					'targetCustomer',
					'targetCustomerChecklist',
					'competitorLogos',
					'aLogoOrBrandYouAdmire',
					'timestamp'
				];
				const innerFieldsInOrderByField = JSON.parse(
					'{"you":["name","email","phoneNumber","position"],"brandDetails":["name","possibleSloganOrTagline","webPage","industry"],"targetCustomer":["age","gender","location","hobbiesAndInterests","jobTitle","maritalStatus","goalsOrMotivations","description"]}'
				);
				const res = {};

				fieldsInOrder.forEach(key => {
					if (key in innerFieldsInOrderByField) {
						res[key] = {};
						innerFieldsInOrderByField[key].forEach(innerKey => {
							res[key][innerKey] = raw[key][innerKey];
						});
					} else {
						res[key] = raw[key];
					}
				});

				return res;
			}
		}
	};
</script>

<style scoped>
	#main {
		background-color: var(--light-grey);
		padding: 20px;
		margin-top: 10vh;
	}

	#header,
	main {
		background-color: var(--white);
		padding: 20px;
		margin-bottom: 20px;
	}

	#header h1 {
		color: var(--black);
		text-align: center;
	}

	#header h2 {
		color: var(--text);
	}

	#header p {
		text-align: center;
		font-size: 0.8rem;
	}

	p,
	h2 {
		color: var(--black);
	}

	.part img {
		border: 3px solid transparent;
		padding: 5px;
		height: 150px;
		background-color: #fff;
	}

	.part img:hover {
		cursor: pointer;
		border-color: var(--text);
	}

	.part {
		padding: 10px 20px;
		background-color: var(--white);
	}

	.part h2 {
		color: var(--black);
	}

	@media screen and (max-width: 1023px) {
		#main {
			padding: 0;
		}

		.part {
			padding: 5px 8px;
		}

		.part img {
			max-height: 80px;
		}
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
